import { TTDict } from "../../../../types";

export const CONTACT_TYPE_OPTIONS = ["Outbound", "Inbound", "Rückruf", "E-Mail", "Datensortierung"].map((opt) => ({
  value: opt,
  label: opt,
}));

export const CALL_RESULT_OPTIONS = ["Termin", "Wiedervorlage", "kein Interesse"];

export const EMAIL_TYPES = ["Kontaktaufnahme", "beantwortet"];

export const STATUS_VALUE_MAPPING: TTDict<string, TTDict<string, string>> = {
  contactType: {
    Datensortierung: "Dublette",
  },
  "attrs.spokenPerson": {
    Firmenmitarbeiter: "on hold",
  },
  "attrs.result": {
    Termin: "Termin",
    Wiedervorlage: "Wiedervorlage",
    "kein Interesse": "kein Interesse",
  },
};

export const HAUCK_USER_ID = "2924";
